import axios from '../axios'
export async function uploadImg(fileData) {
  var { file } = fileData
  if (file && file != '') {
    console.log(file)
    const res = await axios.get('getOSSKey', {})
    var data = res.data
    var ossData = new FormData()
    ossData.append('name', file.name)
    let filename = `${Date.parse(new Date())}` + file.name //图片名称
    let keyValue = data.dir + filename
    //请求数据
    ossData.append('key', keyValue)
    ossData.append('callback', data.callback)
    ossData.append('policy', data.policy)
    ossData.append('OSSAccessKeyId', data.accessid)
    ossData.append('success_action_status', 200)
    ossData.append('signature', data.signature)
    ossData.append('file', file)
    // 请求上传
    const relut = await imgUpload(data.host, ossData) //请求上传图片
    let list = dataUrl(relut.Status) //出来上传成功后的反面数据
    return list.filename
  }
}

export function setNumbers(num) {
  var y = String(num).indexOf('.') + 1 //获取小数点的位置
  const count = String(num).length - y //获取小数点后的个数
  if (y > 0) {
    if (Number(num) >= 1000000) {
      return (num / 10000).toFixed(4) + '万'
    } else {
      if (count >= 4) {
        return num.toFixed(4)
      } else {
        return num
      }
    }
  } else {
    return num
  }
}
