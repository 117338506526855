<template>
  <!-- 头部 -->
  <div
    style="
      font-size: 15px;
      margin-right: 20px;
      display: flex;
      border-bottom: 1px solid #333;
    "
  >
    <div style="flex: 1; font-size: 18px">
      <span
        :class="collapseBtnClass"
        style="cursor: pointer"
        @click="collapse"
      ></span>
    </div>
    <el-dropdown style="cursor: pointer" @command="handleCommand">
      <span style="margin-right: 10px">{{ user.adminName }}</span>
      <i class="el-icon-arrow-down"></i>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="information">个人信息</el-dropdown-item>
        <el-dropdown-item command="logout">退出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    sideWidth: {
      type: Number,
    },
  },
  data() {
    return {
      collapseBtnClass: "el-icon-s-fold",
      isCollapse: false,
      user: "",
    };
  },
  created() {
    // console.log(window.sessionStorage.getItem("admin"));
    this.user = JSON.parse(window.sessionStorage.getItem("admin"));
  },
  methods: {
    //   点击收缩按钮触发
    collapse() {
      this.isCollapse = !this.isCollapse;
      if (this.isCollapse) {
        // this.$emit("sideWidths", 64);
        // this.$store.commit("OnCollapse", false);
        // this.$store.commit("OnWidths", 64);
        this.collapseBtnClass = "el-icon-s-unfold";

        this.$store.commit("OnCollapse", true);
        this.$store.commit("OnWidths", 64);
      } else {
        this.$store.commit("OnCollapse", false);
        this.$store.commit("OnWidths", 200);
        this.collapseBtnClass = "el-icon-s-fold";
      }
    },
    // 退出登录
    handleCommand(command) {
      // command==logout 就退出登录
      if (command == "logout") {
        this.$router.push("/login");
        sessionStorage.clear();
      }
      if (command == "information") {
        this.$message("查看个人信息");
      }
    },
  },
};
</script>

<style></style>
