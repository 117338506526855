import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueQuillEditor from 'vue-quill-editor'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor /* { default global options } */)

// 引入Element UI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// 引入excel表格导出
import JsonExcel from 'vue-json-excel'
Vue.component("downloadExcel",JsonExcel)

import './assets/gloable.css'
Vue.use(ElementUI)

import axios from './axios'
Vue.prototype.axios = axios
import {setNumbers} from './utils/index.js'
Vue.prototype.$setNumbers = setNumbers
Vue.config.productionTip = false

// 路由守卫
// router.beforeEach((to, form, next) => {
//   console.log(localStorage.getItem("token"));
//   if (localStorage.getItem("token")) {
//     next();
//   } else {
//     this.$router.push("/login");
//     // this.$router.push("/");
//     next();
//   }
// });

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
