import axios from 'axios'
import qs from 'qs'
import { Message } from 'element-ui'
import Router from './router'

axios.defaults.timeout = 10000 //响应时间
axios.defaults.headers.post['Content-Type'] = 'application/json' //配置请求头
// axios.defaults.baseURL = "http://42.193.14.241:9070"; //配置接口地址
// axios.defaults.baseURL = 'http://192.168.0.105:9012' //配置接口地址
// axios.defaults.baseURL = 'http://4619p19v09.qicp.vip' //配置接口地址
// axios.defaults.baseURL = 'http://1.12.255.89:9070' //配置接口地址

// axios.defaults.baseURL = 'https://huifupay.ztfzex.vip' //配置接口地址
axios.defaults.baseURL = 'https://huifupay.hrtzjy.top' //配置接口地址
// axios.defaults.baseURL = 'http://47.109.88.30:9070' //配置接口地址
// console.log(process.env);
//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(
  config => {
    //在发送请求之前做某件事
    let token = sessionStorage.getItem('token') || '' //获取token
    // console.log(token);
    if (token != '') {
      config.headers['token'] = token
    }
    // if (config.method === "post") {
    //   config.data = qs.stringify(config.data); //序列化post 参数
    // }
    return config
  },
  error => {
    console.log('错误的传参')
    return Promise.reject(error)
  }
)
//返回状态判断(添加响应拦截器)
axios.interceptors.response.use(
  res => {
    console.log(res)
    //对响应数据做些事
    if (res.data.code == '100002' || res.data.code == '100001') {
      console.log('100002', res)
      // let token = res.data.data.token; //成功后更新token
      // localStorage.setItem("token", token);
      localStorage.removeItem('token')
      Router.push('/login')
    }
    return res
  },
  error => {
    Message({
      message: '网络错误',
      type: 'error'
    })
    // this.$message.error("服务错误！");
    // if (error.response.data.status == "401") {
    //   //如果token 过期 则跳转到登录页面
    //   this.$router.push("/login");
    // }
    return Promise.reject(error)
  }
)
export default {
  get(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, { params: params })
        .then(
          response => {
            resolve(response)
          },
          err => {
            reject(err)
          }
        )
        .catch(error => {
          reject(error)
        })
    })
  },
  // post(url,)
  post(url, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then(
          response => {
            resolve(response)
          },
          err => {
            reject(err)
          }
        )
        .catch(error => {
          reject(error)
        })
    })
  },
  delete(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .delete(url, params)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  put(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .put(url, params)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  excel(url, params) {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
          'Content-Type': 'application/vnd.ms-excel;charset=UTF-8'
        },
        method: 'get',
        url,
        params,
        responseType: 'blob'
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
