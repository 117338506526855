<template>
  <!-- 内容主体 -->
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Main",
  props: {},
  data() {
    
    return {
     
    };
  },
  created() {},
  methods: {},
};
</script>

<style></style>
