<template>
  <div style="height: 100%">
    <el-container style="height: 100%">
      <el-aside
        :width="this.$store.state.sideWidths + 'px'"
        class="ment"
        style="box-shadow: 2px 0 6px rgb(0 21 41 /35%)"
      >
        <Sidebar></Sidebar>
      </el-aside>
      <el-container>
        <el-header>
          <Header></Header>
        </el-header>
        <el-main>
          <Main></Main>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Sidebar from "../components/sidebar";
import Header from "../components/heaher";
import Main from "../components/main";
export default {
  name: "Index",
  components: {
    Sidebar,
    Header,
    Main,
  },
  data() {
    return {
      isCollapse: false,
      sideWidth: 200, // 默认200px
    };
  },
  methods: {
    //   点击收缩按钮触发
    sideWidths(index) {
      console.log(index);
      this.sideWidth = index;
    },
  },
};
</script>

<style>
.el-header {
  /* background-color: #b3c0d1; */
  color: #333;
  line-height: 60px;
}

.el-aside {
  background-color: #fff;
  color: #333;
}
.ment {
  overflow-x: hidden !important;
}
</style>
